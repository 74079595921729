
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useParams, Navigate } from 'react-router-dom';
import { Calendar, ArrowLeft, Tag } from 'lucide-react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import SearchEngineSchema from '@/components/seo/SearchEngineSchema';

// Import the blog posts data
import { getBlogPostById, getRelatedPosts } from '@/services/blogService';
import type { BlogPost } from '@/services/blogService';

const BlogPost = () => {
  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<BlogPost | undefined>(getBlogPostById(id || ''));
  const [relatedPosts, setRelatedPosts] = useState<BlogPost[]>([]);
  
  useEffect(() => {
    const currentPost = getBlogPostById(id || '');
    if (currentPost) {
      setPost(currentPost);
      setRelatedPosts(getRelatedPosts(currentPost.id, 3));
    }
  }, [id]);
  
  if (!post) {
    return <Navigate to="/blog" replace />;
  }

  return (
    <>
      <Helmet>
        <title>{post.title} | CAMBIRDS Blog</title>
        <meta name="description" content={post.excerpt} />
        <link rel="canonical" href={`https://cambirds.com${post.slug}`} />
      </Helmet>

      <SearchEngineSchema
        type="Article"
        name={post.title}
        description={post.excerpt}
        url={`https://cambirds.com${post.slug}`}
        image={post.image}
        datePublished={post.date}
        dateModified={post.date}
        breadcrumbs={[
          { name: "Home", item: "https://cambirds.com/", position: 1 },
          { name: "Blog", item: "https://cambirds.com/blog", position: 2 },
          { name: post.title, item: `https://cambirds.com${post.slug}`, position: 3 }
        ]}
        author={{
          name: "CAMBIRDS Editorial Team"
        }}
      />

      <div className="pt-16 sm:pt-20 md:pt-24 min-h-screen bg-background">
        <div className="container mx-auto px-4 max-w-4xl">
          <Breadcrumb className="my-4">
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to="/">Home</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to="/blog">Blog</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink className="max-w-[200px] truncate">
                  {post.title}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>

          <div className="mb-6">
            <Link 
              to="/blog" 
              className="text-accent hover:text-accent/80 flex items-center mb-4"
            >
              <ArrowLeft size={16} className="mr-1" /> Back to Blog
            </Link>
          </div>

          <div className="glass-card overflow-hidden rounded-lg border border-accent/20 mb-8">
            <div className="aspect-video bg-card">
              {post.image && (
                <img 
                  src={post.image} 
                  alt={post.title} 
                  className="w-full h-full object-cover" 
                  onError={(e) => {
                    // If image fails to load, log the error
                    console.error("Failed to load image:", post.image);
                    e.currentTarget.src = "https://images.unsplash.com/photo-1649972904349-6e44c42644a7?q=80&w=1000";
                  }}
                />
              )}
            </div>
            
            <div className="p-6 sm:p-8">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center text-muted-foreground text-sm">
                  <Calendar size={14} className="mr-1" />
                  {new Date(post.date).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })}
                </div>
                <div className="flex items-center">
                  <Tag size={14} className="mr-1 text-accent" />
                  <span className="text-accent text-sm">{post.category}</span>
                </div>
              </div>
              
              <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white mb-4">
                {post.title}
              </h1>
              
              <div className="prose prose-sm sm:prose prose-invert max-w-none">
                <div dangerouslySetInnerHTML={{ __html: post.content }} />
              </div>
            </div>
          </div>

          <div className="mb-12">
            <h2 className="text-xl font-bold text-white mb-4">Related Articles</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {relatedPosts.map(relatedPost => (
                <Link 
                  to={relatedPost.slug} 
                  key={relatedPost.id}
                  className="glass-card p-4 rounded-lg hover:border-accent/40 border border-accent/20 transition-all"
                >
                  <h3 className="text-lg font-medium text-white mb-2 line-clamp-2">
                    {relatedPost.title}
                  </h3>
                  <p className="text-sm text-muted-foreground line-clamp-2">
                    {relatedPost.excerpt}
                  </p>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;
