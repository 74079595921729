
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "@/components/ui/sonner";
import { ThemeProvider } from "@/components/ThemeProvider";
import Layout from "@/components/layout/Layout";

// Public Pages
import Index from "@/pages/Index";
import NotFound from "@/pages/NotFound";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import TermsOfService from "@/pages/TermsOfService";
import Faq from "@/pages/Faq";
import ProfileDetail from "@/pages/ProfileDetail";
import SearchResults from "@/pages/SearchResults";
import Blog from "@/pages/Blog";
import BlogPost from "@/pages/BlogPost";

// Admin Pages
import AdminLogin from "@/pages/admin/Login";
import AdminDashboard from "@/pages/admin/Dashboard";
import AdminBlogPosts from "@/pages/admin/BlogPosts";
import BlogPostEditor from "@/pages/admin/BlogPostEditor";
import ProtectedRoute from "@/components/admin/ProtectedRoute";

// Escort Pages
import EscortsIndex from "@/pages/escorts/EscortsIndex";
import ChineseEscortsLondon from "@/pages/escorts/ChineseEscortsLondon";
import KoreanEscortsLondon from "@/pages/escorts/KoreanEscortsLondon";
import DoncasterEscorts from "@/pages/escorts/DoncasterEscorts";
import LutonEscorts from "@/pages/escorts/LutonEscorts";
import MiltonKeynesEscorts from "@/pages/escorts/MiltonKeynesEscorts";
import NewcastleUponTyneEscorts from "@/pages/escorts/NewcastleUponTyneEscorts";
import StokeOnTrentEscorts from "@/pages/escorts/StokeOnTrentEscorts";
import MayfairEscorts from "@/pages/escorts/MayfairEscorts";
import KnightsbridgeEscorts from "@/pages/escorts/KnightsbridgeEscorts";
import StAlbansEscorts from "@/pages/escorts/StAlbansEscorts";

// Massage Pages
import MassageIndex from "@/pages/massage/MassageIndex";
import CardiffHappyEnding from "@/pages/massage/CardiffHappyEnding";

// Dominatrix Pages
import DominatrixIndex from "@/pages/dominatrix/DominatrixIndex";
import KentDominatrix from "@/pages/dominatrix/KentDominatrix";
import LeicesterDominatrix from "@/pages/dominatrix/LeicesterDominatrix";
import NorthamptonDominatrix from "@/pages/dominatrix/NorthamptonDominatrix";
import NorthwestDominatrix from "@/pages/dominatrix/NorthwestDominatrix";
import SussexDominatrix from "@/pages/dominatrix/SussexDominatrix";
import WalesDominatrix from "@/pages/dominatrix/WalesDominatrix";

// Bdsm Pages
import BdsmIndex from "@/pages/bdsm/BdsmIndex";
import SwanseaBdsm from "@/pages/bdsm/SwanseaBdsm";

// Mistress Pages
import MistressIndex from "@/pages/mistress/MistressIndex";
import ChesterMistress from "@/pages/mistress/ChesterMistress";
import CardiffMistress from "@/pages/mistress/CardiffMistress";
import WirralMistress from "@/pages/mistress/WirralMistress";

function App() {
  return (
    <ThemeProvider defaultTheme="dark" storageKey="cambirds-theme">
      <Router>
        <Layout>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Index />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogPost />} />
            <Route path="/profile/:id" element={<ProfileDetail />} />

            {/* Admin Routes - These should not have the standard layout */}
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/dashboard" element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            } />
            <Route path="/admin/blog-posts" element={
              <ProtectedRoute>
                <AdminBlogPosts />
              </ProtectedRoute>
            } />
            <Route path="/admin/blog-posts/new" element={
              <ProtectedRoute>
                <BlogPostEditor />
              </ProtectedRoute>
            } />
            <Route path="/admin/blog-posts/edit/:id" element={
              <ProtectedRoute>
                <BlogPostEditor />
              </ProtectedRoute>
            } />

            {/* Escorts Routes */}
            <Route path="/escorts" element={<EscortsIndex />} />
            <Route path="/escorts/chinese-escorts-london" element={<ChineseEscortsLondon />} />
            <Route path="/escorts/korean-escorts-london" element={<KoreanEscortsLondon />} />
            <Route path="/escorts/doncaster" element={<DoncasterEscorts />} />
            <Route path="/escorts/luton" element={<LutonEscorts />} />
            <Route path="/escorts/milton-keynes" element={<MiltonKeynesEscorts />} />
            <Route path="/escorts/newcastle-upon-tyne" element={<NewcastleUponTyneEscorts />} />
            <Route path="/escorts/stoke-on-trent" element={<StokeOnTrentEscorts />} />
            <Route path="/escorts/london/mayfair" element={<MayfairEscorts />} />
            <Route path="/escorts/london/knightsbridge" element={<KnightsbridgeEscorts />} />
            <Route path="/escorts/st-albans" element={<StAlbansEscorts />} />

            {/* Massage Routes */}
            <Route path="/massage" element={<MassageIndex />} />
            <Route path="/massage/cardiff-happy-ending" element={<CardiffHappyEnding />} />

            {/* Dominatrix Routes */}
            <Route path="/dominatrix" element={<DominatrixIndex />} />
            <Route path="/dominatrix/kent" element={<KentDominatrix />} />
            <Route path="/dominatrix/leicester" element={<LeicesterDominatrix />} />
            <Route path="/dominatrix/northampton" element={<NorthamptonDominatrix />} />
            <Route path="/dominatrix/northwest" element={<NorthwestDominatrix />} />
            <Route path="/dominatrix/sussex" element={<SussexDominatrix />} />
            <Route path="/dominatrix/wales" element={<WalesDominatrix />} />

            {/* BDSM Routes */}
            <Route path="/bdsm" element={<BdsmIndex />} />
            <Route path="/bdsm/swansea" element={<SwanseaBdsm />} />

            {/* Mistress Routes */}
            <Route path="/mistress" element={<MistressIndex />} />
            <Route path="/mistress/chester" element={<ChesterMistress />} />
            <Route path="/mistress/cardiff" element={<CardiffMistress />} />
            <Route path="/mistress/wirral" element={<WirralMistress />} />

            {/* 404 Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Toaster />
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
