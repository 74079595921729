
import React from 'react';
import SeoPageLayout from '@/components/seo/SeoPageLayout';

const MayfairEscorts = () => {
  return (
    <SeoPageLayout
      title="Mayfair Escorts | Luxury Companions in London W1 | CAMBIRDS"
      description="Find elite Mayfair escorts for luxury companionship in London's most prestigious district. Upscale companions available for dinner dates, events, and private encounters."
      canonicalUrl="https://cambirds.com/escorts/london/mayfair"
      pageType="escorts"
      location="London"
      subLocation="Mayfair"
      breadcrumbs={[
        { name: 'Home', link: '/' },
        { name: 'Escorts', link: '/search?type=escorts' },
        { name: 'London', link: '/search?location=london' },
        { name: 'Mayfair', link: '/escorts/london/mayfair' },
      ]}
      h1="Mayfair Escorts"
      h2="Luxury Companions in London's Premier District"
      introText="Discover exclusive Mayfair escorts offering premium companionship in London's most prestigious district. Our verified selection includes sophisticated ladies providing luxurious experiences for discerning clients in elegant Mayfair surroundings."
      contentSections={[
        {
          title: "Elite Mayfair Escort Services",
          content: "Our Mayfair escorts offer exceptional companionship experiences, from sophisticated dinner engagements at Michelin-starred restaurants to attending high-profile events and intimate private encounters. Each companion is selected for their elegance, intelligence, and ability to provide discreet, premium services."
        },
        {
          title: "Luxury Accommodations in Mayfair",
          content: "Mayfair escorts typically provide incall services in luxurious apartments and penthouses throughout the W1 district, including around Berkeley Square, Park Lane, and Grosvenor Square. Many also offer outcall services to upscale hotels such as The Dorchester, Claridge's, and The Connaught."
        },
        {
          title: "Booking a Mayfair Escort",
          content: "When arranging a meeting with a Mayfair escort, advance booking is recommended to ensure availability. Our verified companions maintain busy schedules catering to an exclusive clientele, and typically require notice for appointments, particularly for evening engagements or extended bookings."
        }
      ]}
      relatedLinks={[
        {
          title: "Related London Areas",
          links: [
            { text: "Knightsbridge Escorts", url: "/escorts/london/knightsbridge" },
            { text: "Kensington Escorts", url: "/escorts/london/kensington" },
            { text: "Chelsea Escorts", url: "/escorts/london/chelsea" },
          ]
        },
        {
          title: "London Escort Categories",
          links: [
            { text: "Chinese Escorts London", url: "/escorts/london/chinese" },
            { text: "Korean Escorts London", url: "/escorts/london/korean" },
            { text: "VIP London Escorts", url: "/escorts/london/vip" },
          ]
        }
      ]}
      schemaType="LocalBusiness"
    />
  );
};

export default MayfairEscorts;
